import { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocale } from '@core/utils/locale';
import Toggle, { ToggleType } from '@components/utility/Toggle/Toggle';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { FilterTypeEnum, OperatorTargetingPhrasesEnum } from '@enums/localeEnum';
import { CriterionEnum, CriterionParam, CriterionParamFilter } from '@core/models/Criterion';

export const INCLUDE_WITHOUT_MENU_ID = 'MenuIncludeOperatorsWithoutMenu';

const OnTheMenuIncludesCheckbox = () => {
    const [isToggleChecked, setIsToggleChecked] = useState<boolean>(false);
    const { t } = useLocale();

    const {
        onAddCriterionParam,
        getCriterionByFilterKey,
        onDeleteCriterionParam
    } = useAdvancedSearch();

    const checkboxParam = useMemo(() => {
        const menuParams = getCriterionByFilterKey(CriterionEnum.CuisineTypes, CriterionParamFilter.MenuIncludes);
        return menuParams?.find((keyword) => keyword?.value?.toString() === INCLUDE_WITHOUT_MENU_ID);
    }, [getCriterionByFilterKey]);

    useEffect(() => {
        setIsToggleChecked(!!checkboxParam);
    }, [checkboxParam]);

    const shouldDisableCheckbox = useMemo(() => {
        const includesMenuParams = getCriterionByFilterKey(
            CriterionEnum.CuisineTypes,
            CriterionParamFilter.MenuIncludes,
        )?.filter((param) => param.value !== INCLUDE_WITHOUT_MENU_ID);

        const excludeMenuParams = getCriterionByFilterKey(
            CriterionEnum.CuisineTypes,
            CriterionParamFilter.MenuExcludes,
        );

        if (excludeMenuParams?.length || includesMenuParams?.length) {
            return false;
        }

        return true;
    }, [getCriterionByFilterKey]);

    useEffect(() => {
        if (shouldDisableCheckbox === true) {
            onDeleteCriterionParam(CriterionEnum.CuisineTypes, INCLUDE_WITHOUT_MENU_ID);
        }
    }, [onDeleteCriterionParam, shouldDisableCheckbox]);

    const onToggleCheckbox = useCallback(() => {
        const tagParam: CriterionParam = {
            filterKey: CriterionParamFilter.MenuIncludes,
            name: `${t(FilterTypeEnum.MenuIncludes)}: ${t(OperatorTargetingPhrasesEnum.IncludeOperatorsWithoutMenu)}`,
            value: INCLUDE_WITHOUT_MENU_ID,
            additionalData: {
                condensedLabel: t(OperatorTargetingPhrasesEnum.IncludeOperatorsWithoutMenu),
            },
        };

        onAddCriterionParam(CriterionEnum.CuisineTypes, tagParam);
    }, [t, onAddCriterionParam]);

    return (
        <Toggle
            type={ToggleType.Checkbox}
            isChecked={isToggleChecked}
            setIsChecked={(val) => {
                if (val) {
                    return onToggleCheckbox();
                }

                return onDeleteCriterionParam(CriterionEnum.CuisineTypes, INCLUDE_WITHOUT_MENU_ID);
            }}
            testId="checkboxOnTheMenuIncludes"
            isDisabled={shouldDisableCheckbox}
        >
            {t(OperatorTargetingPhrasesEnum.IncludeOperatorsWithoutMenu)}
        </Toggle>
    );
};

export default OnTheMenuIncludesCheckbox;
