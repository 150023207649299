import { useCallback, useState, useMemo } from 'react';

import { paths } from '@core/api/api';
import { useLocale } from '@core/utils/locale';
import useFetch, { Config } from '@hooks/useFetch';
import { ExportPhrasesEnum } from '@core/enums/localeEnum';
import { OperatorFilter } from '@graphql/generated/graphql';
import { stringifyObjectForGraphQL } from '@core/utils/string';
import { buildQuery, getExportTimestamp } from '@core/models/Export';
import { Content, MethodsEnum, OperationsEnum } from '@core/enums/api';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import { MIMETypes, downloadExport, getFileExtensionFromMimeType } from '@core/utils/file';

import { useAppContext } from '../../App.context';

const POST_EXPORTS = '/export';

type GetExportEndpoint = paths[typeof POST_EXPORTS][MethodsEnum.POST];
type ExportParams = GetExportEndpoint[OperationsEnum.PARAMETERS]['query'];
type ExportBody = GetExportEndpoint[OperationsEnum.REQUEST][Content]['application/json'];

export const DEFAULT_EXPORT_PARAMS: ExportParams = {
    format: 'CSV',
    delimiter: 'SEMICOLON',
};

export default function useExportOperators() {
    const [hasExported, setHasExported] = useState(false);
    const {
        locale,
        t
    } = useLocale();

    const isTagsExportEnabled = useFeatureFlag(FeatureFlags.TagsExport);
    const isFormExportDownloadEnabled = useFeatureFlag(FeatureFlags.FormExportDownload);
    const isCustomerStatusExportEnabled = useFeatureFlag(FeatureFlags.CustomerStatusExport);

    const {
        userInfo: {
            clientExternalIdType,
            features
        },
    } = useAppContext();

    const premiumFields = useMemo(() => features?.premium_field ?? [], [features?.premium_field]);

    const {
        cancel,
        data,
        loading,
        error,
        doFetch
    } = useFetch<BlobPart, ExportParams, ExportBody>({
        lazy: true,
        url: POST_EXPORTS,
        method: MethodsEnum.POST,
        params: DEFAULT_EXPORT_PARAMS,
        initialConfig: {
            maxBodyLength: Infinity,
            headers: {
                'Content-Type': 'text/plain',
            },
        },
    });

    const getExportFallbackFilename = useCallback(
        (exportFormat: MIMETypes) => {
            const fileExension = getFileExtensionFromMimeType(exportFormat);

            return `${t(ExportPhrasesEnum.ExportFileName)}_${getExportTimestamp()}.${fileExension}`;
        },
        [t],
    );

    const getApiConfigFromExportType = useCallback((exportType: MIMETypes): Config<ExportBody, ExportParams> => {
        const params: ExportParams = {
            format: exportType === MIMETypes.XLSX ? 'EXCEL' : 'CSV',
            delimiter: DEFAULT_EXPORT_PARAMS.delimiter,
        };

        return {
            params,
            responseType: exportType === MIMETypes.XLSX ? 'arraybuffer' : undefined,
        };
    }, []);

    const doFetchAndDownload = useCallback(
        (filters: OperatorFilter, exportType: MIMETypes) => {
            setHasExported(false);
            const stringifiedOperatorFilters = stringifyObjectForGraphQL(filters);
            const config = getApiConfigFromExportType(exportType);
            const fallbackFilename = getExportFallbackFilename(exportType);
            const bodyQuery = buildQuery(
                stringifiedOperatorFilters,
                locale,
                t,
                clientExternalIdType,
                isTagsExportEnabled,
                isCustomerStatusExportEnabled,
                premiumFields,
            );

            if (isFormExportDownloadEnabled) {
                return fetch(
                    `${process.env.REACT_APP_API}${POST_EXPORTS}?${new URLSearchParams(config.params).toString()}`,
                    {
                        method: 'POST',
                        credentials: 'include',
                        headers: { 'Content-Type': 'application/json' },
                        body: bodyQuery,
                    },
                )
                    .then((res) => downloadExport(res, exportType, fallbackFilename))
                    .catch((err) => console.error('Error streaming operator export:', err))
                    .finally(() => setHasExported(true));
            }

            doFetch({ data: bodyQuery, ...config })
                .then((res) => (res ? downloadExport(res, exportType, fallbackFilename) : null))
                .catch((err) => console.error('Error exporting operators:', err))
                .finally(() => setHasExported(true));
        },
        [
            clientExternalIdType,
            doFetch,
            getApiConfigFromExportType,
            getExportFallbackFilename,
            isCustomerStatusExportEnabled,
            isFormExportDownloadEnabled,
            isTagsExportEnabled,
            locale,
            t,
            premiumFields,
        ],
    );

    return {
        data,
        doFetchAndDownload,
        error,
        hasExported,
        isExportLoading: loading,
        onCancelExport: cancel,
    } as const;
}
