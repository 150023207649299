import { FC } from 'react';
import cn from 'classnames';

import Text, { TextVariant } from '@components/ui/Typography/Text';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';

const ServiceList: FC<{
    services: string[];
    title: string;
    useGridLayout?: boolean;
}> = ({
    services,
    title,
    useGridLayout = false
}) => {
    if (!services.length) return null;

    return (
        <div
            className={cn({
                'ProfileDetails__comma-layout-container': !useGridLayout,
            })}
        >
            <Text variant={TextVariant.B2} testId={`heading${title}`}>
                {title}
            </Text>
            {!useGridLayout ? (
                <Heading variant={HeadingVariant.H5} testId={`testService${title}`}>
                    {services.join(', ')}
                </Heading>
            ) : (
                <ul className="ProfileDetails__service-list">
                    {services.map((service) => (
                        <li key={service}>
                            <Heading variant={HeadingVariant.H5} testId={`testService${service}`}>
                                {service}
                            </Heading>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ServiceList;
