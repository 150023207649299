import { useCallback } from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { animated, useTransition } from 'react-spring';

import { FCX } from '@core/models';
import { useInput } from '@core/hooks/useInput';
import Chip from '@components/utility/Chips/Chip';
import { formatTestIds } from '@core/utils/string';

import './TagsInput.styles.scss';
import InformationTooltip from '@components/utility/Tooltip/InformationTooltip';
import Tooltip from '@components/utility/Tooltip/Tooltip';
import { IconInformation } from '@assets/icons';

type TagsInputProps = {
    isDisabled?: boolean;
    placeholder?: string;
    inputLabel?: string;
    tooltipContent?: string;
    onAddTag: (id: string) => void;
    onDeleteTag: (id: string) => void;
    tags: string[];
};

const AnimatedChip = animated(Chip);

const TagsInput: FCX<TagsInputProps> = ({
    isDisabled,
    placeholder,
    inputLabel,
    tooltipContent,
    onAddTag,
    onDeleteTag,
    tags,
    testId,
}) => {
    const {
        value,
        onChange
    } = useInput();

    const parsedTestId = formatTestIds(testId);

    const onKeyDownEvent = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {
                e.preventDefault();
                if (value) {
                    onAddTag(value);
                    onChange('');
                }
            }
        },
        [onAddTag, onChange, value],
    );

    const onPasteEvent = useCallback(
        (e: React.ClipboardEvent<HTMLDivElement>) => {
            e.preventDefault();
            const pastedText = e.clipboardData.getData('Text');
            if (pastedText) {
                onAddTag(pastedText);
                onChange('');
            }
        },
        [onAddTag, onChange],
    );

    const onBlurEvent = useCallback(() => {
        if (value) {
            onAddTag(value);
            onChange('');
        }
    }, [onAddTag, onChange, value]);

    const transitions = useTransition(tags, {
        from: { transform: 'scale(0.7)', opacity: 0 },
        enter: { transform: 'scale(1)', opacity: 1, config: { tension: 270, friction: 20 } },
        leave: { transform: 'scale(0)', config: { duration: 150 } },
    });

    return (
        <div className="TagsInput">
            <div className="TagsInput__label">
                {!!inputLabel && <InputLabel data-testid={`label${parsedTestId}`}>{inputLabel}</InputLabel>}
                <div className="TagsInput__tooltip">
                    <Tooltip
                        content={<InformationTooltip title={''} description={tooltipContent ?? ''} />}
                        placement="right-end"
                    >
                        <div>
                            <IconInformation />
                        </div>
                    </Tooltip>
                </div>
            </div>
            <TextField
                value={value}
                onChange={(e) => {
                    e.preventDefault();
                    onChange(e.target.value);
                }}
                onKeyDown={onKeyDownEvent}
                variant="outlined"
                fullWidth
                onPasteCapture={onPasteEvent}
                onBlur={onBlurEvent}
                disabled={isDisabled}
                placeholder={tags?.length > 0 ? undefined : placeholder}
                InputProps={{
                    classes: {
                        root: 'TagsInput__root',
                        input: 'TagsInput__input',
                    },
                    componentsProps: {
                        input: {
                            // @ts-expect-error "data-testid" unsupported by MUI
                            'data-testid': `tagsInput${parsedTestId}`,
                        },
                    },
                    startAdornment: transitions((style, item) => (
                        <AnimatedChip
                            key={item}
                            onChipDelete={() => onDeleteTag(item)}
                            id={item}
                            name={item}
                            style={style}
                        />
                    )),
                    'data-testid': `tagsInputContainer${parsedTestId}`,
                }}
            />
        </div>
    );
};

export default TagsInput;
