import { FC } from 'react';
import cn from 'classnames';

import { useLocale } from '@core/utils/locale';
import { isEmpty } from '@core/helpers/helpers';
import { capitalize } from '@core/utils/string';
import { IconInformation } from '@assets/icons';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { CommonPhrasesEnum, OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';

import Tooltip from '@components/utility/Tooltip/Tooltip';
import Text, { TextVariant } from '@components/ui/Typography/Text';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import InformationTooltip from '@components/utility/Tooltip/InformationTooltip';
import { OperatorDetails, OperatorPurchasePotential } from '@graphql/generated/graphql';

import './Details.styles.scss';
import { useOperatorRanges } from '../OperatorProfile.helpers';
import { ServiceList, ReviewBox, RatingStars, PurchasePotential, Business, ProfileTags } from './components';

const Details: FC<{
    data: OperatorDetails;
    services: {
        dayPartsServices: string[];
        offersServices: string[];
        alcoholServices: string[];
    };
}> = ({
    data,
    services
}) => {
    const { t } = useLocale();

    const shouldRenderExperienceSection = data?.total_average_review_rating && data?.total_review_count;
    const shouldRenderServiceSection =
        services.dayPartsServices.length > 0 ||
        services.offersServices.length > 0 ||
        services.alcoholServices.length > 0;

    // TODO: Include Chain Size Range after 11/22 release
    const operatorRanges = useOperatorRanges(
        data?.ranges?.filter((r) => r?.type != 'CHAIN_SIZE_RANGE'),
        data?.location?.country as CountryCodesEnum,
    );
    const yearFounded = data?.date_founded ? new Date(data.date_founded).getUTCFullYear() : null;
    {
        /* TODO: Discuss a cleaner approach to handle validations of shouldRender sections 
      as each section consists of custom set of fields. */
    }
    const shouldRenderBusinessInfoSection = !isEmpty(operatorRanges) || !isEmpty(yearFounded);

    return (
        <Widget type={WidgetType.Section} className="section-small">
            <div className="ProfileDetails">
                {shouldRenderBusinessInfoSection && (
                    <div className="ProfileDetails__section col-4">
                        <Business data={data} />
                    </div>
                )}
                {data?.purchase_potential && data.purchase_potential.length > 1 && (
                    <PurchasePotential
                        purchaseData={data.purchase_potential as OperatorPurchasePotential[]}
                        countryCode={data.location?.country as CountryCodesEnum}
                    />
                )}

                <ProfileTags tagsData={data?.tags} />

                {shouldRenderServiceSection && (
                    <div className="ProfileDetails__section col-2">
                        <Heading
                            variant={HeadingVariant.H4}
                            className="ProfileDetails__section-title"
                            testId="DetailsService"
                        >
                            {t(OperatorProfilePhrasesEnum.Service)}
                        </Heading>
                        <div className="ProfileDetails__service-columns">
                            <ServiceList
                                services={services.dayPartsServices}
                                title={t(OperatorProfilePhrasesEnum.DayParts)}
                            />
                            <ServiceList
                                services={services.alcoholServices}
                                title={t(OperatorProfilePhrasesEnum.AlcoholService)}
                            />
                        </div>
                        <ServiceList
                            services={services.offersServices}
                            title={t(OperatorProfilePhrasesEnum.Offers)}
                            useGridLayout={true}
                        />
                    </div>
                )}
                {shouldRenderExperienceSection && (
                    <div
                        className={cn('ProfileDetails__section', {
                            'col-3': data?.popularity_score,
                            'col-4': !data?.popularity_score,
                        })}
                    >
                        <Heading
                            variant={HeadingVariant.H4}
                            className="ProfileDetails__section-title"
                            testId="DetailsExperience"
                        >
                            {t(OperatorProfilePhrasesEnum.Experience)}{' '}
                        </Heading>
                        <div className="ProfileDetails__experience">
                            <div>
                                <Text variant={TextVariant.B2} testId="headingExperienceAverageReviewRating">
                                    {t(OperatorProfilePhrasesEnum.AverageReviewRating)}
                                </Text>
                                <RatingStars rating={data?.total_average_review_rating.toFixed(1)} />

                                <Text
                                    variant={TextVariant.B2}
                                    style={{ marginTop: '24px', display: 'block' }}
                                    testId="headingExperienceTotalReviewCount"
                                >
                                    {t(OperatorProfilePhrasesEnum.TotalReviewCount)}
                                </Text>
                                <Text
                                    variant={TextVariant.B1}
                                    style={{ fontWeight: 600 }}
                                    testId="textExperienceTotalReviewCountValue"
                                >
                                    {data.total_review_count}
                                </Text>
                            </div>
                            {data?.social_media && data.social_media.length > 0 && (
                                <>
                                    <div className="ProfileDetails__experience-divider" />
                                    <div className="ProfileDetails__experience-reviews">
                                        {data.social_media.map((socialMedia, index) => (
                                            <ReviewBox
                                                key={index}
                                                name={socialMedia?.type ?? ''}
                                                rating={socialMedia?.average_rating ?? 0}
                                                reviewCount={socialMedia?.total_reviews ?? 0}
                                                testId={capitalize(socialMedia?.type ?? '')}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
                {data?.popularity_score && (
                    <div className="ProfileDetails__section col-1">
                        <Heading
                            testId="DetailsPopularityScoreLabel"
                            variant={HeadingVariant.H4}
                            className="ProfileDetails__section-title"
                        >
                            {t(OperatorProfilePhrasesEnum.PopularityScore)}
                            <Tooltip
                                content={
                                    <InformationTooltip
                                        title={t(OperatorProfilePhrasesEnum.PopularityScore)}
                                        description={t(CommonPhrasesEnum.PopularityScoreToolTipDesc)}
                                    />
                                }
                                placement="bottom"
                            >
                                <div>
                                    <IconInformation />
                                </div>
                            </Tooltip>
                        </Heading>

                        <div className="ProfileDetails__popularity">
                            <Heading testId="DetailsPopularityScoreValue" variant={HeadingVariant.H1}>
                                {data?.popularity_score}
                            </Heading>
                        </div>
                    </div>
                )}
            </div>
        </Widget>
    );
};

export default Details;
