import { useEffect, useMemo, useState } from 'react';

import { isEmpty } from '@core/helpers/helpers';
import { SegmentCodesEnum } from '@core/models/Segments';
import { OperatorFilterFactory } from '@core/models/OperatorFilter';
import { OperatorSegmentsFilter } from '@core/models/OperatorSegmentsFilter';
import { Maybe, OperatorFilter, OperatorType, StringExpression } from '@graphql/generated/graphql';
import { CriteriaData, CriterionParamFilter, NoEmployeesCountDataEnum } from '@core/models/Criterion';

/** This is the default operator type that will always be present in the search filters. */
const DEFAULT_OPERATOR_TYPE: OperatorFilter = { type: [OperatorType.Unit] };

type FilterProcessor = (filters: OperatorFilter[]) => OperatorFilter[];

function composeProcessors(...processors: FilterProcessor[]): FilterProcessor {
    return (filters: OperatorFilter[]) => {
        return processors.reduce((acc, processor) => processor(acc), filters);
    };
}

const processFilters = composeProcessors(
    OperatorSegmentsFilter.proccessSegments.bind(OperatorSegmentsFilter),
    processNumberOfEmployeesCustomFilters,
    processTagsCustomFilters,
    processTerritoriesFilters,
    processMenuFilters,
);

function useAdvancedSearchFilters(criteria: CriteriaData | undefined) {
    const [filters, setFilters] = useState<OperatorFilter[]>([]);

    useEffect(() => {
        if (criteria !== undefined) {
            const operatorFilters = OperatorFilterFactory.buildFilters(criteria);

            setFilters(processFilters(operatorFilters));
        }
    }, [criteria]);

    const searchFilters = useMemo(() => {
        if (filters.length === 0) {
            return undefined;
        }

        return { and: [DEFAULT_OPERATOR_TYPE, ...filters] };
    }, [filters]);

    return { searchFilters };
}

function processMenuFilters(filters: OperatorFilter[]): OperatorFilter[] {
    const processAndFilters = (andFilters: OperatorFilter[]): OperatorFilter[] => {
        return andFilters.map((andFilter) => {
            if (CriterionParamFilter.MenuIncludes in andFilter) {
                return { ...(andFilter[CriterionParamFilter.MenuIncludes] as OperatorFilter) };
            }

            if (CriterionParamFilter.MenuExcludes in andFilter) {
                return { ...(andFilter[CriterionParamFilter.MenuExcludes] as OperatorFilter) };
            }

            if (CriterionParamFilter.HasMenu in andFilter) {
                return { ...(andFilter[CriterionParamFilter.HasMenu] as OperatorFilter) };
            }

            return andFilter;
        });
    };

    const processOrFilters = (orFilters: OperatorFilter[]): OperatorFilter[] => {
        return orFilters.map((orFilter) => {
            if (orFilter.and) {
                return { ...orFilter, and: processAndFilters(orFilter.and) };
            }

            return orFilter;
        });
    };

    const withProcessedMenuKeys = filters.map((filter) => {
        if (filter.or) {
            return { ...filter, or: processOrFilters(filter.or) };
        }

        return filter;
    });

    return withProcessedMenuKeys;
}

/**
 * Processes the given filters to handle the Territories filter key.
 *
 * This function traverses the provided filters and creates a new array where the Territories filter key is removed,
 * but its values are preserved. It specifically looks for filters with an `or` clause because territories lie inside the Location "or" clause.
 *
 * @param {OperatorFilter[]} filters - The array of filters to process.
 * @returns {OperatorFilter[]} - The processed array of filters with the Territories filter key removed.
 */
function processTerritoriesFilters(filters: OperatorFilter[]): OperatorFilter[] {
    const newFilters = filters.map((filter) => {
        if (filter.or) {
            const newOrFilters = filter.or.map((orFilter) => {
                if (CriterionParamFilter.Territories in orFilter) {
                    return { ...(orFilter[CriterionParamFilter.Territories] as OperatorFilter) };
                }

                return orFilter;
            });

            return { ...filter, or: newOrFilters };
        }

        return filter;
    });

    return newFilters;
}

function processTagsCustomFilters(filters: OperatorFilter[]): OperatorFilter[] {
    const foodTags = OperatorSegmentsFilter.getOperatorFilterByKey(filters, CriterionParamFilter.FoodTags)?.[
        CriterionParamFilter.FoodTags as keyof OperatorFilter
    ] as StringExpression;

    const placeTags = OperatorSegmentsFilter.getOperatorFilterByKey(filters, CriterionParamFilter.PlaceTags)?.[
        CriterionParamFilter.PlaceTags as keyof OperatorFilter
    ] as StringExpression;

    if (!foodTags && !placeTags) {
        return filters;
    }

    const filtersWithoutTags = filters.filter((filter) => {
        return !(CriterionParamFilter.FoodTags in filter || CriterionParamFilter.PlaceTags in filter);
    });

    const combinedTagsFilter = {
        or: [...(foodTags ? [{ tags: foodTags }] : []), ...(placeTags ? [{ tags: placeTags }] : [])],
    };

    return [...filtersWithoutTags, combinedTagsFilter];
}

const NonCommercialCodeMatch = `${SegmentCodesEnum.NonCommercial}.%`;
const NoEmployeesDataCodesMatch = 'R02.%9%';
const COMMERCIAL_KEY = CriterionParamFilter.NumberOfEmployeesRangeCommercial;
const NON_COMMERCIAL_KEY = CriterionParamFilter.NumberOfEmployeesRangeNonCommercial;
const NO_EMPLOYEES_FILTERS = {
    commercial: {
        and: [
            { number_of_employees_range: { contains: NoEmployeesDataCodesMatch } },
            { not: { segmentation: { contains: NonCommercialCodeMatch } } },
        ],
    },
    nonCommercial: {
        and: [
            { number_of_employees_range: { contains: NoEmployeesDataCodesMatch } },
            { segmentation: { contains: NonCommercialCodeMatch } },
        ],
    },
    both: { number_of_employees_range: { contains: NoEmployeesDataCodesMatch } },
};

/**
 * This function processes the NumberOfEmployeesRange filters by pasring to the correct api filter key and handling whenever the user filters for 'No Count Data' on commercial or non-commercial.
 * The function will return the original filters if none of the NumberOfEmployeesRange filter keys are present.
 *
 * @param filters OperatorFilter[]
 * @returns OperatorFilter[] with the NumberOfEmployeesRange filters processed.
 */
function processNumberOfEmployeesCustomFilters(filters: OperatorFilter[]): OperatorFilter[] {
    const commercialEmployees = OperatorSegmentsFilter.getOperatorFilterByKey(filters, COMMERCIAL_KEY);
    const nonCommercialEmployees = OperatorSegmentsFilter.getOperatorFilterByKey(filters, NON_COMMERCIAL_KEY);

    if (!commercialEmployees && !nonCommercialEmployees) {
        return filters;
    }

    const [isNoDataCommercialActive, parsedCommercialEmployees] = parseNumberOfEmployees(
        commercialEmployees,
        COMMERCIAL_KEY,
    );

    const [isNoDataNonCommercialActive, parsedNonCommercialEmployees] = parseNumberOfEmployees(
        nonCommercialEmployees,
        NON_COMMERCIAL_KEY,
    );

    const filtersWithoutNumberOfEmployees = filters.filter((filter) => {
        return !(COMMERCIAL_KEY in filter || NON_COMMERCIAL_KEY in filter);
    });

    const combinedEmployeesData = [
        ...(parsedCommercialEmployees ? [parsedCommercialEmployees] : []),
        ...(parsedNonCommercialEmployees ? [parsedNonCommercialEmployees] : []),
    ];

    if (isNoDataCommercialActive && isNoDataNonCommercialActive) {
        return [
            ...filtersWithoutNumberOfEmployees,
            ...(combinedEmployeesData.length >= 2
                ? [{ or: [NO_EMPLOYEES_FILTERS.both, ...combinedEmployeesData] }]
                : []),
        ];
    }

    const finalFilters = [
        ...(isNoDataCommercialActive ? [NO_EMPLOYEES_FILTERS.commercial] : []),
        ...(isNoDataNonCommercialActive ? [NO_EMPLOYEES_FILTERS.nonCommercial] : []),
        ...combinedEmployeesData,
    ];

    return [...filtersWithoutNumberOfEmployees, ...(finalFilters.length > 0 ? [{ or: finalFilters }] : [])];
}

function parseNumberOfEmployees(
    employeesFilter: OperatorFilter | undefined,
    filterKey:
        | CriterionParamFilter.NumberOfEmployeesRangeCommercial
        | CriterionParamFilter.NumberOfEmployeesRangeNonCommercial,
): [boolean, OperatorFilter | undefined] {
    let IsNoEmployeeCountDataActive = false;
    const noEmployeesCountDataEnum =
        filterKey === CriterionParamFilter.NumberOfEmployeesRangeCommercial
            ? NoEmployeesCountDataEnum.Commercial
            : NoEmployeesCountDataEnum.NonCommercial;

    const parsedValues: StringExpression = Object.entries(
        employeesFilter?.[filterKey as keyof OperatorFilter] || {},
    ).reduce(
        (acc, [key, values]) => {
            if (!Array.isArray(values)) {
                values = [values];
            }

            const isNoEmployeeCountDataIncluded = values?.includes(noEmployeesCountDataEnum);

            if (isNoEmployeeCountDataIncluded) {
                const newValues = values?.filter((currVal: string) => currVal !== noEmployeesCountDataEnum);

                if (newValues.length > 0) {
                    IsNoEmployeeCountDataActive = true;
                    return { ...acc, [key]: newValues };
                }

                return acc;
            }

            return {
                ...acc,
                [key]: values.length === 1 ? values[0] : values,
            };
        },
        {} as Record<string, string[]>,
    );

    const parsedNumberOfEmployeesExpression: Maybe<OperatorFilter> = isEmpty(parsedValues)
        ? undefined
        : {
            number_of_employees_range: parsedValues,
        };

    return [IsNoEmployeeCountDataActive, parsedNumberOfEmployeesExpression];
}

export default useAdvancedSearchFilters;
