import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useLocale } from '@core/utils/locale';
import Tooltip from '@components/utility/Tooltip/Tooltip';
import { SidebarPhrasesEnum } from '@core/enums/localeEnum';
import { IconDoubleArrow, IconLogout, IconDSLogoWhite, IconDSFullLogoWhite } from '@assets/icons';

import Link from './Link';
import './Sidebar.styles.scss';
import { INavLink, useNavLinks } from './navLinks';

const SIGN_OUT_URL = `${process.env.REACT_APP_API}/sign-out`;

const Sidebar: React.FC<{
    userName: string;
    isMenuOpen: boolean;
    setIsMenuOpen: (isMenuOpen: boolean) => void;
}> = ({
    userName,
    isMenuOpen,
    setIsMenuOpen
}): JSX.Element => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const theme = useTheme();
    const { t } = useLocale();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const isMobileOrExpanded = useMemo(() => !isCollapsed || isMobile, [isCollapsed, isMobile]);

    const {
        navLinks,
        adminNavLinks
    } = useNavLinks();

    useEffect(() => {
        if (isMobile) {
            setIsCollapsed(false);
        }
    }, [isMobile]);

    const getInitials = (name: string): string => {
        const words = name.split(' ').filter(Boolean);
        const initials = words.map((word) => word.charAt(0).toUpperCase()).join('');
        return initials.substring(0, 2);
    };

    const onLinkClick = useCallback(() => {
        if (!isMobile) {
            setIsCollapsed(true);
        }
        setIsMenuOpen(false);
    }, [isMobile, setIsMenuOpen]);

    return (
        <nav className={cn('Sidebar', isCollapsed && 'Sidebar--collapsed', isMenuOpen && 'Sidebar--open')}>
            <div className="Sidebar__content">
                <div className="Sidebar__logo">
                    {isMobile ? (
                        <>
                            <IconDSFullLogoWhite data-testid="imageDatassentialFullLogo" />
                            <div
                                data-testid="SidebarHamburgerIcon"
                                className={cn('Sidebar-mobile-icon', isMenuOpen && 'Sidebar-mobile-icon--open')}
                                onClick={() => setIsMenuOpen(!isMenuOpen)}
                            >
                                <span />
                                <span />
                                <span />
                            </div>
                        </>
                    ) : (
                        <>
                            <IconDSLogoWhite
                                className={cn(!isCollapsed && 'hidden', 'logo')}
                                data-testid="imageDatassentialLogo"
                            />
                            <IconDSFullLogoWhite
                                className={cn(isCollapsed && 'hidden', 'logo')}
                                data-testid="imageDatassentialFullLogo"
                            />
                            <div
                                data-testid="SidebarCollapsibleIcon"
                                className={cn('Sidebar__collapsible', isCollapsed && 'Sidebar__collapsible--collapsed')}
                                onClick={() => setIsCollapsed(!isCollapsed)}
                            >
                                <IconDoubleArrow />
                            </div>
                        </>
                    )}
                </div>

                {navLinks.map((link: INavLink) => (
                    <Link key={link.link} isCollapsed={isCollapsed} onLinkClick={onLinkClick} {...link} />
                ))}

                {adminNavLinks.length > 0 && (
                    <>
                        <div className="Sidebar__admin-separator"></div>
                        {adminNavLinks.map((link: INavLink) => (
                            <Link key={link.link} isCollapsed={isCollapsed} onLinkClick={onLinkClick} {...link} />
                        ))}
                    </>
                )}
            </div>
            <div className="Sidebar__avatar">
                <div onClick={() => !isMobile && setIsCollapsed(!isCollapsed)}>
                    <Avatar data-testid="SidebarAvatar" sx={{ bgcolor: 'primary.main' }}>
                        {getInitials(userName)}
                    </Avatar>
                    {isMobileOrExpanded && <span data-testid="SidebarTextUserName">{userName}</span>}
                </div>
                {isMobileOrExpanded && (
                    <Tooltip content={t(SidebarPhrasesEnum.Logout)} placement="top">
                        <div className="Sidebar__tooltip">
                            <IconLogout
                                data-testid="SidebarIconLogout"
                                onClick={() => {
                                    window.location.href = SIGN_OUT_URL;
                                }}
                            />
                        </div>
                    </Tooltip>
                )}
            </div>
        </nav>
    );
};

export default Sidebar;
