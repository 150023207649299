import { useState, useCallback } from 'react';
import cn from 'classnames';

import { FCX } from '@core/models';

import './Toggle.styles.scss';

export enum ToggleType {
    Checkbox = 'checkbox',
    Radio = 'radio',
    Switch = 'switch',
}

const Toggle: FCX<{
    type: ToggleType;
    isDisabled?: boolean;
    isChecked: boolean;
    setIsChecked: (value: boolean) => void;
}> = ({
    type,
    isDisabled = false,
    isChecked,
    setIsChecked,
    className,
    testId,
    children,
    style
}) => {
    const [hasInteracted, setHasInteracted] = useState(false);

    const handleMouseLeave = useCallback(() => {
        setHasInteracted(false);
    }, []);

    const handleClick = useCallback(() => {
        setHasInteracted(true);
        setIsChecked(!isChecked);
    }, [isChecked, setIsChecked]);

    return (
        <label
            role="toggle"
            className={cn(
                className,
                'Toggle',
                isDisabled && 'is-disabled',
                isChecked && 'is-checked',
                hasInteracted && 'has-interacted',
            )}
            data-testid={testId}
            style={style}
            onMouseLeave={handleMouseLeave}
        >
            <input
                type="checkbox"
                checked={isChecked}
                disabled={isDisabled}
                className="Toggle__input"
                onChange={handleClick}
            />
            <div role="icon" className={cn('Toggle__icon', `is-${type}`)} data-testid={`${testId}Icon`} />
            <div role="label" className="Toggle__label" data-testid={`${testId}Label`}>
                {children}
            </div>
        </label>
    );
};

export default Toggle;
