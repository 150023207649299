import { useMemo, type FC, useEffect } from 'react';

import { useLocale } from '@core/utils/locale';
import Tabs from '@components/utility/Tabs/Tabs';
import { useAdvancedSearch } from '@core/contexts';
import { CriteriaData } from '@core/models/Criterion';
import useLocalStorage from '@core/hooks/useLocalStorage';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import { OperatorTargetingResultsPhrasesEnum } from '@core/enums/localeEnum';

import {
    OperatorTargetingResultsHeader,
    OperatorResultsList,
    OperatorResultsSummary,
    OperatorResultsMap,
} from './components';
import './OperatorTargetingResults.styles.scss';
import { DRILL_DOWN_LOCAL_STORAGE } from './hooks/useGetOperatorSummaryData';
import { OperatorTargetingResultsProvider } from './OperatorTargetingResults.context';

const OperatorTargetingResults: FC = () => {
    const { t } = useLocale();
    const {
        onMergeCriteria,
        openTable,
        setOpenTable
    } = useAdvancedSearch();
    const isMapResultsEnabled = useFeatureFlag(FeatureFlags.ResultsMap);

    const {
        value: storedCriteria,
        deleteValue
    } = useLocalStorage<CriteriaData | undefined>(
        DRILL_DOWN_LOCAL_STORAGE,
        undefined,
    );

    useEffect(() => {
        if (storedCriteria) {
            setTimeout(() => {
                onMergeCriteria(storedCriteria);
                deleteValue();
            }, 600);
        }
    }, [deleteValue, onMergeCriteria, storedCriteria]);

    const targetResultsTabs = useMemo(() => {
        return [
            {
                label: t(OperatorTargetingResultsPhrasesEnum.Summary),
                content: <OperatorResultsSummary />,
                title: 'ResultsSummary',
            },
            {
                label: t(OperatorTargetingResultsPhrasesEnum.List),
                content: <OperatorResultsList />,
                title: 'ResultsList',
            },
            ...(isMapResultsEnabled
                ? [
                    {
                        label: t(OperatorTargetingResultsPhrasesEnum.Map),
                        content: <OperatorResultsMap />,
                        title: 'ResultsMap',
                    },
                ]
                : []),
        ];
    }, [isMapResultsEnabled, t]);

    const initialTab = useMemo(() => {
        if (openTable) {
            setOpenTable(false);
            // The index of List tab is 1
            return 1;
        }
        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <OperatorTargetingResultsProvider>
            <div className="OperatorTargetingResults">
                <OperatorTargetingResultsHeader />
                <Tabs tabsContent={targetResultsTabs} initialTab={initialTab} />
            </div>
        </OperatorTargetingResultsProvider>
    );
};

export default OperatorTargetingResults;
