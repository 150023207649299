import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { isEmpty } from '@core/helpers/helpers';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { OperatorDetails, useGetOperatorQuery } from '@graphql/generated/graphql';
import { mapApiDayPartsServices, mapApiOffersServices } from './OperatorProfile.helpers';
import { useLocale } from '@core/utils/locale';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import { equalInLC } from '@core/utils/string';

enum ApiAlcoholServiceType {
    BeerWineOnly = 'beer wine only',
    FullBar = 'full bar',
    UnspecifiedAlcohol = 'unspecified alcohol',
}
const mapServiceTypeToTranslation: Record<ApiAlcoholServiceType, OperatorProfilePhrasesEnum> = {
    [ApiAlcoholServiceType.BeerWineOnly]: OperatorProfilePhrasesEnum.BeerWineOnly,
    [ApiAlcoholServiceType.FullBar]: OperatorProfilePhrasesEnum.FullBar,
    [ApiAlcoholServiceType.UnspecifiedAlcohol]: OperatorProfilePhrasesEnum.AlcoholUnspecified,
};

export function useOperatorProfile() {
    const navigate = useNavigate();
    const { id } = useParams();
    const {
        t,
        locale
    } = useLocale();

    const {
        data,
        loading
    } = useGetOperatorQuery({
        variables: {
            operatorIdentifier: id ?? '',
            locale,
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (id && !loading && isEmpty(data?.get)) {
            navigate(RoutesEnum.NotFound);
        }
    }, [data, loading, navigate, id]);

    const dayPartsServices = useMemo(() => {
        const dayParts: string[] = [];

        if (!data?.get) {
            return dayParts;
        }

        for (const [key, _] of Object.entries(data.get)) {
            const part = mapApiDayPartsServices[key];

            if (part) {
                dayParts.push(t(part));
            }
        }

        return dayParts;
    }, [data?.get, t]);

    const offersServices = useMemo(() => {
        const offers: string[] = [];

        if (!data?.get) {
            return offers;
        }

        for (const [key, value] of Object.entries(data.get)) {
            const part = mapApiOffersServices[key];

            if (part && value === true) {
                offers.push(t(part));
            }

            if (key === 'offers_fuel') {
                offers.push(
                    mapApiOffersServices['offers_fuel']
                        ? t(OperatorProfilePhrasesEnum.PetrolLocation)
                        : t(OperatorProfilePhrasesEnum.NonPetrolLocation),
                );
            }
        }

        return offers;
    }, [data?.get, t]);

    const alcoholServices = useMemo(() => {
        if (!data?.get) {
            return [];
        }

        if (data.get.offers_alcohol === false) {
            return [t(OperatorProfilePhrasesEnum.NonAlcohol)];
        }

        const alcoholServiceType = data.get.alcohol_service_type as string;
        if (!alcoholServiceType) {
            return [t(OperatorProfilePhrasesEnum.AlcoholUnspecified)];
        }

        const matchedType = Object.values(ApiAlcoholServiceType).find((type) => equalInLC(type, alcoholServiceType));

        return [
            t(matchedType ? mapServiceTypeToTranslation[matchedType] : OperatorProfilePhrasesEnum.AlcoholUnspecified),
        ];
    }, [data?.get, t]);

    return {
        loading,
        data: data?.get as OperatorDetails,
        services: {
            dayPartsServices,
            offersServices,
            alcoholServices,
        },
    };
}
