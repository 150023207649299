import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from '@mui/material';

import { FCX, ID } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { formatTestIds } from '@core/utils/string';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import SwitchList from '@components/utility/SwitchList/SwitchList';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { CommonPhrasesEnum, FilterTypeEnum } from '@core/enums/localeEnum';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { CriterionEnum, CriterionParam, CriterionParamFilter, HasMenuOptionsEnum } from '@core/models/Criterion';

const hasMenuKey = CriterionParamFilter.HasMenu;

const HasMenuCriteria: FCX = () => {
    const [selectedOption, setSelectedOption] = useState<ID[]>([]);

    const { t } = useLocale();
    const isSmallScreen = useMediaQuery('(max-width:1200px)');

    const {
        onAddCriterionParam,
        getCriterionByFilterKey,
        onResetCriteriaByFilterKey
    } = useAdvancedSearch();

    useEffect(() => {
        const hasMenuParam = getCriterionByFilterKey(CriterionEnum.CuisineTypes, hasMenuKey);
        setSelectedOption(hasMenuParam ? [hasMenuParam[0].value] : []);
    }, [getCriterionByFilterKey]);

    const hasMenuOptions = useMemo(
        () => [
            {
                id: HasMenuOptionsEnum.Yes,
                title: t(CommonPhrasesEnum.Yes),
                className: 'SwitchList__list-item--small',
            },
            {
                id: HasMenuOptionsEnum.No,
                title: t(CommonPhrasesEnum.No),
                className: 'SwitchList__list-item--small',
            },
        ],
        [t],
    );

    const onUpdateSelectedItems = useCallback(
        (values: ID[]) => {
            if (!values.length) {
                return onResetCriteriaByFilterKey(CriterionEnum.CuisineTypes, hasMenuKey);
            }

            const newOption = hasMenuOptions.find((option) => {
                return option.id === values[values.length - 1];
            });

            if (newOption) {
                const hasMenuParam: CriterionParam = {
                    filterKey: hasMenuKey,
                    name: `${t(FilterTypeEnum.HasMenu)}: ${newOption.title}`,
                    value: newOption.id,
                };

                onAddCriterionParam(CriterionEnum.CuisineTypes, hasMenuParam);
            }
        },
        [hasMenuOptions, onAddCriterionParam, onResetCriteriaByFilterKey, t],
    );

    return (
        <div className="CriteriaContent__column col-1">
            <Heading
                className="GeneralCriteria__range-title"
                variant={HeadingVariant.H5}
                testId={formatTestIds(FilterTypeEnum.HasMenu)}
            >
                {t(FilterTypeEnum.HasMenu)}
            </Heading>
            <SwitchList
                options={hasMenuOptions}
                value={selectedOption}
                onChange={onUpdateSelectedItems}
                color={GradientColorsEnum.PURPLE}
                isSmall={isSmallScreen}
                columns={5}
            />
        </div>
    );
};

export default HasMenuCriteria;
