import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import useUser, { UserRolesEnum } from '@core/api/useUser';
import useTerritories from '@core/api/useTerritories';
import { PermissionsStructure } from '@core/hooks/usePermissions';
import { getLocaleFromString, LocalesEnum } from '@enums/localeEnum';

type UserInfo = {
    id: string;
    locale?: LocalesEnum;
    fullName: string;
    permissions: PermissionsStructure | null;
    features?: {
        leadscore?: boolean;
        premium_field?: string[];
        roles?: string[];
    };
    defaultCountry: string;
    exportAllowed: boolean;
    clientExternalIdType: string;
};

type AppContextType = {
    userInfo: UserInfo;
    isLoading: boolean;
    isUserId: (id?: string) => boolean;
    hasTerritoryAccess: boolean;
    isAdmin: boolean;
};

const DEFAULT_CONTEXT_VALUE: AppContextType = {
    userInfo: {
        id: '',
        fullName: '',
        permissions: null,
        defaultCountry: '',
        exportAllowed: false,
        features: undefined,
        clientExternalIdType: '',
    },
    isLoading: true,
    isUserId: () => false,
    hasTerritoryAccess: false,
    isAdmin: false,
};

export const AppContext = createContext<AppContextType>(DEFAULT_CONTEXT_VALUE);

export function AppProvider({ children }: { children: ReactNode }) {
    const [userInfo, setUserInfo] = useState<UserInfo>(DEFAULT_CONTEXT_VALUE.userInfo);
    const {
        data,
        loading: isUserLoading
    } = useUser();

    const { data: territoriesData } = useTerritories({ lazy: false });
    const hasTerritoryAccess = useMemo(() => territoriesData?.territories?.length !== 0, [territoriesData]);

    const isLoading = useMemo(() => {
        return !!(isUserLoading || !userInfo.fullName || territoriesData === null);
    }, [isUserLoading, territoriesData, userInfo.fullName]);

    const isUserId = useCallback((id?: string) => id === userInfo.id, [userInfo.id]);

    const isAdmin = useMemo(
        () => userInfo?.features?.roles?.includes(UserRolesEnum.Admin) ?? false,
        [userInfo?.features?.roles],
    );

    useEffect(() => {
        if (data) {
            setUserInfo({
                id: data.external_id,
                locale: getLocaleFromString(data?.locale ?? data?.company?.default_locale ?? ''),
                fullName: `${data.first_name} ${data.last_name}`,
                permissions: data.permissions,
                defaultCountry: data?.country ?? data?.company?.default_country ?? '',
                exportAllowed: data?.company?.export_allowed ?? false,
                features: data?.features,
                clientExternalIdType: data?.company?.client_external_id_type,
            });
        }
    }, [data]);

    const contextValue = useMemo(
        () => ({ userInfo, isLoading, isUserId, isAdmin, hasTerritoryAccess }),
        [userInfo, isLoading, isUserId, isAdmin, hasTerritoryAccess],
    );

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
}

export function useAppContext() {
    return useContext(AppContext);
}
