import { useCallback, useEffect, useMemo, useState } from 'react';

import {
    FilterTypeEnum,
    OperatorProfilePhrasesEnum,
    OperatorTargetingPhrasesEnum,
    TableColumnsEnum,
    TerritoriesPhrasesEnum,
} from '@enums/localeEnum';
import { ID } from '@core/models';
import { useLocale } from '@utils/locale';
import useCuisines from '@core/api/useCuisines';
import { useAdvancedSearch } from '@core/contexts';
import { TagsCategoryEnum } from '@core/api/useTags';
import { generateAnchorId } from '@core/utils/string';
import { RangesTypesEnum } from '@core/api/useRanges';
import useSegmentations from '@core/api/useSegmentation';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import { useGetUserFieldPermissionsQuery } from '@graphql/generated/graphql';
import { iNavigationItem } from '@components/ui/SearchNavigation/SearchNavigation';
import useCustomerMetadata, { CustomerMetadataTypeEnum } from '@core/api/useCustomerMetadata';
import { IconFilter, IconFilterLocation, IconFilterSegments, IconFilterTags } from '@assets/icons';
import { NAV_TEST_IDS_MAP, OperatorTargetingNavItem, SEGMENT_NAV_TYPES } from '@core/models/CriteriaNavigation';

import useTagsCriteria from './useTagsCriteria';
import useGeneralCriteria from './useGeneralCriteria';
import { LocationContent, SegmentsContent, SegmentsCriteriaContent, TagsContent } from '../helpers/Criteria.helpers';
import useTerritories from '@core/api/useTerritories';
import { useAppContext } from '../../../App.context';

export const FILTER_ID_PREFIX = 'filter-';

function useGetNavigation() {
    const [activeNavigationItemId, setActiveNavigationItemId] = useState<ID>(0);
    const [activeNavigationFilterId, setActiveNavigationFilterId] = useState<ID | null>(null);
    const [activeNavigationSubItemId, setActiveNavigationSubItemId] = useState<ID | null>(null);

    const { activeCountry } = useAdvancedSearch();

    const {
        t,
        locale
    } = useLocale();

    const {
        data: cuisines,
        doFetch: doFetchCuisines
    } = useCuisines();

    const {
        data: segmentations,
        doFetch: doFetchSegmentations
    } = useSegmentations();

    const {
        data: territories,
        doFetch: doFetchTerritories
    } = useTerritories();

    const { hasTerritoryAccess } = useAppContext();

    const { tags } = useTagsCriteria(activeCountry?.value as string | undefined, locale);
    const { ranges } = useGeneralCriteria();
    const {
        data: customerMetadata,
        error
    } = useCustomerMetadata({
        country: activeCountry?.value,
        customer_metadata_type: CustomerMetadataTypeEnum.STATUS,
    });
    const { data } = useGetUserFieldPermissionsQuery({
        variables: {
            filter: {
                and: [{ menu_text: { contains: '' } }, { country: { equals: activeCountry?.value as string } }],
            },
        },
        skip: !activeCountry?.value,
    });

    const isLodgingEnabled = useFeatureFlag(FeatureFlags.LodgingNav);
    const isHotelClassEnabled = useFeatureFlag(FeatureFlags.HotelClass);
    const isTagsFilterEnabled = useFeatureFlag(FeatureFlags.TagsFilter);
    const isMenuSearchEnabled = useFeatureFlag(FeatureFlags.MenuSearch);
    const isAverageCheckEnabled = useFeatureFlag(FeatureFlags.AverageCheck);
    const isNumberOfRoomsEnabled = useFeatureFlag(FeatureFlags.NumberOfRooms);
    const isEstMealsPerDayEnabled = useFeatureFlag(FeatureFlags.EstMealsPerDay);
    const isPurchasePotEnabled = useFeatureFlag(FeatureFlags.PurchasePotentialFilter);
    const isCustomerStatusSearchEnabled = useFeatureFlag(FeatureFlags.CustomerStatusSearch);
    const isLocationTerritoriesFavoritesEnabled = useFeatureFlag(FeatureFlags.LocationTerritoriesFavorites);

    useEffect(() => {
        if (activeCountry) {
            const params = { country: activeCountry.value as string, locale };

            doFetchCuisines({ params });
            doFetchSegmentations({ params });
            doFetchTerritories({ params });
        }
    }, [activeCountry, doFetchCuisines, doFetchSegmentations, doFetchTerritories, locale]);

    const filterSegmentNavTypes = SEGMENT_NAV_TYPES.map((segmentNavType) => ({
        title: t(segmentNavType),
        id: generateAnchorId(t(segmentNavType)),
        testId: NAV_TEST_IDS_MAP[segmentNavType],
    }));

    const filteredSegmentNavList = useMemo(() => {
        return (
            segmentations?.children?.flatMap((segmentation) => {
                return filterSegmentNavTypes.filter((filterObject) => {
                    if (filterObject.title !== segmentation?.description) {
                        return false;
                    }
                    const allSegmentsInactive = segmentation?.children?.every((child) =>
                        child?.children?.every((grandChild) => grandChild.active === false),
                    );

                    return !allSegmentsInactive;
                });
            }) || []
        );
    }, [segmentations, filterSegmentNavTypes]);

    const NAVIGATION: OperatorTargetingNavItem[] = useMemo(() => {
        const items: OperatorTargetingNavItem[] = [
            {
                id: 1,
                title: t(FilterTypeEnum.Location),
                testId: NAV_TEST_IDS_MAP[FilterTypeEnum.Location] ?? '',
                content: <LocationContent onUpdateActiveNavigationSubItem={onUpdateActiveNavigationSubItem} />,
                icon: <IconFilterLocation />,
                baseColor: GradientColorsEnum.GREEN,
                subItems: [
                    ...(!error &&
                    isLocationTerritoriesFavoritesEnabled &&
                    hasTerritoryAccess &&
                    !!territories?.territories?.length
                        ? [
                            {
                                title: t(TerritoriesPhrasesEnum.TerritoryFavorites),
                                id: generateAnchorId(t(TerritoriesPhrasesEnum.TerritoryFavorites)),
                                testId: NAV_TEST_IDS_MAP[TerritoriesPhrasesEnum.TerritoryFavorites] ?? '',
                            },
                        ]
                        : []),
                    {
                        title: t(FilterTypeEnum.Regional),
                        id: generateAnchorId(t(FilterTypeEnum.Regional)),
                        testId: NAV_TEST_IDS_MAP[FilterTypeEnum.Regional] ?? '',
                    },
                    {
                        title: t(FilterTypeEnum.Radius),
                        id: generateAnchorId(t(FilterTypeEnum.Radius)),
                        testId: NAV_TEST_IDS_MAP[FilterTypeEnum.Radius] ?? '',
                    },
                ],
            },
            {
                id: 2,
                title: t(FilterTypeEnum.Segments),
                testId: NAV_TEST_IDS_MAP[FilterTypeEnum.Segments] ?? '',
                content: (
                    <SegmentsContent
                        onUpdateActiveNavigationSubItem={onUpdateActiveNavigationSubItem}
                        segmentations={segmentations}
                    />
                ),
                icon: <IconFilterSegments />,
                baseColor: GradientColorsEnum.BLUE,
                subItems: SEGMENT_NAV_TYPES.map((segmentNavType) => ({
                    title: t(segmentNavType),
                    id: generateAnchorId(t(segmentNavType)),
                    testId: NAV_TEST_IDS_MAP[segmentNavType] ?? '',
                })).filter((subItem) =>
                    filteredSegmentNavList.some((segmentNav) => segmentNav.title === subItem.title),
                ),
            },
            {
                id: 3,
                title: t(FilterTypeEnum.SegmentsCriteria),
                content: (
                    <SegmentsCriteriaContent
                        onUpdateActiveNavigationSubItem={onUpdateActiveNavigationSubItem}
                        ranges={ranges}
                        cuisines={cuisines}
                        customerMetadata={customerMetadata}
                        hasMenuData={!!data?.get_user_field_permissions?.has_data}
                    />
                ),
                testId: NAV_TEST_IDS_MAP[FilterTypeEnum.SegmentsCriteria] ?? '',
                icon: <IconFilter />,
                baseColor: GradientColorsEnum.PURPLE,
                subItems: [
                    ...(!error && isCustomerStatusSearchEnabled && !!customerMetadata?.values?.length
                        ? [
                            {
                                id: generateAnchorId(t(FilterTypeEnum.ClientFilters)),
                                title: t(FilterTypeEnum.ClientFilters),
                                testId: NAV_TEST_IDS_MAP[FilterTypeEnum.ClientFilters] ?? '',
                                filters: [
                                    {
                                        title: t(FilterTypeEnum.ClientStatus),
                                        id: generateAnchorId(t(FilterTypeEnum.ClientStatus), FILTER_ID_PREFIX),
                                        testId: NAV_TEST_IDS_MAP[FilterTypeEnum.ClientStatus] ?? '',
                                    },
                                ],
                            },
                        ]
                        : []),
                    {
                        id: generateAnchorId(t(FilterTypeEnum.General)),
                        title: t(FilterTypeEnum.General),
                        testId: NAV_TEST_IDS_MAP[FilterTypeEnum.General] ?? '',
                        filters: [
                            ...(ranges?.[RangesTypesEnum.ChainSizeRangeType]
                                ? [
                                    {
                                        title: t(FilterTypeEnum.ChainIndependent),
                                        id: generateAnchorId(t(FilterTypeEnum.ChainIndependent), FILTER_ID_PREFIX),
                                        testId: NAV_TEST_IDS_MAP[FilterTypeEnum.ChainIndependent] ?? '',
                                    },
                                ]
                                : []),
                            ...(ranges?.[RangesTypesEnum.YearsInBusinessRangeType]
                                ? [
                                    {
                                        title: t(OperatorProfilePhrasesEnum.YearsinBusiness),
                                        id: generateAnchorId(
                                            t(OperatorProfilePhrasesEnum.YearsinBusiness),
                                            FILTER_ID_PREFIX,
                                        ),
                                        testId: NAV_TEST_IDS_MAP[FilterTypeEnum.Business] ?? '',
                                    },
                                ]
                                : []),
                            ...(ranges?.[RangesTypesEnum.NumberOfEmployeesCommercialRangeType] &&
                            ranges?.[RangesTypesEnum.NumberOfEmployeesNonCommercialRangeType]
                                ? [
                                    {
                                        title: t(FilterTypeEnum.NumberOfEmployees),
                                        id: generateAnchorId(t(FilterTypeEnum.NumberOfEmployees), FILTER_ID_PREFIX),
                                        testId: NAV_TEST_IDS_MAP[FilterTypeEnum.NumberOfEmployees] ?? '',
                                    },
                                ]
                                : []),
                            ...(ranges?.[RangesTypesEnum.AnnualSalesRangeType]
                                ? [
                                    {
                                        title: t(OperatorProfilePhrasesEnum.EstimatedAnnualSales),
                                        id: generateAnchorId(
                                            t(OperatorProfilePhrasesEnum.EstimatedAnnualSales),
                                            FILTER_ID_PREFIX,
                                        ),
                                        testId:
                                              NAV_TEST_IDS_MAP[OperatorProfilePhrasesEnum.EstimatedAnnualSales] ?? '',
                                    },
                                ]
                                : []),
                            ...(isPurchasePotEnabled && !!ranges?.[RangesTypesEnum.PurchasePotentialTotalRangeType]
                                ? [
                                    {
                                        title: t(FilterTypeEnum.TotalPurchasePotential),
                                        id: generateAnchorId(
                                            t(FilterTypeEnum.TotalPurchasePotential),
                                            FILTER_ID_PREFIX,
                                        ),
                                        testId: NAV_TEST_IDS_MAP[FilterTypeEnum.TotalPurchasePotential] ?? '',
                                    },
                                ]
                                : []),

                            {
                                title: t(OperatorProfilePhrasesEnum.ConfidenceLevel),
                                id: generateAnchorId(t(OperatorProfilePhrasesEnum.ConfidenceLevel), FILTER_ID_PREFIX),
                                testId: NAV_TEST_IDS_MAP[OperatorProfilePhrasesEnum.ConfidenceLevel] ?? '',
                            },
                        ],
                    },
                    {
                        id: generateAnchorId(t(FilterTypeEnum.RestaurantsAndBars)),
                        title: t(FilterTypeEnum.RestaurantsAndBars),
                        testId: NAV_TEST_IDS_MAP[FilterTypeEnum.RestaurantsAndBars] ?? '',
                        filters: [
                            {
                                title: t(FilterTypeEnum.CuisineTypes),
                                id: generateAnchorId(t(TableColumnsEnum.Cuisine), FILTER_ID_PREFIX),
                                testId: NAV_TEST_IDS_MAP[FilterTypeEnum.CuisineTypes] ?? '',
                            },
                            ...(isMenuSearchEnabled && data?.get_user_field_permissions?.has_data === true
                                ? [
                                    {
                                        title: t(OperatorProfilePhrasesEnum.Menu),
                                        id: generateAnchorId(t(OperatorProfilePhrasesEnum.Menu), FILTER_ID_PREFIX),
                                        testId: NAV_TEST_IDS_MAP[OperatorProfilePhrasesEnum.Menu] ?? '',
                                    },
                                ]
                                : []),
                            ...(ranges?.[RangesTypesEnum.EstMealsPerDayRangeType] && isEstMealsPerDayEnabled
                                ? [
                                    {
                                        title: t(OperatorProfilePhrasesEnum.EstMealsPerDay),
                                        id: generateAnchorId(
                                            t(OperatorProfilePhrasesEnum.EstMealsPerDay),
                                            FILTER_ID_PREFIX,
                                        ),
                                        testId: NAV_TEST_IDS_MAP[OperatorProfilePhrasesEnum.EstMealsPerDay] ?? '',
                                    },
                                ]
                                : []),

                            ...(ranges?.[RangesTypesEnum.AverageCheckRangeType] && isAverageCheckEnabled
                                ? [
                                    {
                                        title: t(OperatorTargetingPhrasesEnum.AverageCheck),
                                        id: generateAnchorId(
                                            t(OperatorTargetingPhrasesEnum.AverageCheck),
                                            FILTER_ID_PREFIX,
                                        ),
                                        testId: NAV_TEST_IDS_MAP[OperatorTargetingPhrasesEnum.AverageCheck] ?? '',
                                    },
                                ]
                                : []),
                        ],
                    },
                    ...(isNumberOfRoomsEnabled || isLodgingEnabled
                        ? [
                            {
                                id: generateAnchorId(t(FilterTypeEnum.LodgingAndRecreation)),
                                title: t(FilterTypeEnum.LodgingAndRecreation),
                                testId: NAV_TEST_IDS_MAP[FilterTypeEnum.LodgingAndRecreation] ?? '',
                                filters: [
                                    ...(isNumberOfRoomsEnabled
                                        ? [
                                            {
                                                title: t(FilterTypeEnum.NumberofRooms),
                                                id: generateAnchorId(
                                                    t(FilterTypeEnum.NumberofRooms),
                                                    FILTER_ID_PREFIX,
                                                ),
                                                testId: NAV_TEST_IDS_MAP[FilterTypeEnum.NumberofRooms] ?? '',
                                            },
                                        ]
                                        : []),
                                    ...(isHotelClassEnabled
                                        ? [
                                            {
                                                title: t(OperatorProfilePhrasesEnum.HotelClass),
                                                id: generateAnchorId(
                                                    t(OperatorProfilePhrasesEnum.HotelClass),
                                                    FILTER_ID_PREFIX,
                                                ),
                                                testId:
                                                        NAV_TEST_IDS_MAP[OperatorProfilePhrasesEnum.HotelClass] ?? '',
                                            },
                                        ]
                                        : []),
                                    ...(isLodgingEnabled
                                        ? [
                                            {
                                                title: t(OperatorProfilePhrasesEnum.AverageReviewRating),
                                                id: generateAnchorId(
                                                    t(OperatorProfilePhrasesEnum.AverageReviewRating),
                                                    FILTER_ID_PREFIX,
                                                ),
                                                testId:
                                                        NAV_TEST_IDS_MAP[
                                                            OperatorProfilePhrasesEnum.AverageReviewRating
                                                        ] ?? '',
                                            },
                                            {
                                                title: t(FilterTypeEnum.ServicesOffered),
                                                id: generateAnchorId(
                                                    t(FilterTypeEnum.ServicesOffered),
                                                    FILTER_ID_PREFIX,
                                                ),
                                                testId: NAV_TEST_IDS_MAP[FilterTypeEnum.ServicesOffered] ?? '',
                                            },
                                            {
                                                title: t(OperatorProfilePhrasesEnum.EstMealsPerDay),
                                                id: generateAnchorId(
                                                    t(OperatorProfilePhrasesEnum.EstMealsPerDay),
                                                    FILTER_ID_PREFIX,
                                                ),
                                                testId:
                                                        NAV_TEST_IDS_MAP[OperatorProfilePhrasesEnum.EstMealsPerDay] ??
                                                        '',
                                            },
                                            {
                                                title: t(FilterTypeEnum.EventVenueCapacity),
                                                id: generateAnchorId(
                                                    t(FilterTypeEnum.EventVenueCapacity),
                                                    FILTER_ID_PREFIX,
                                                ),
                                                testId: NAV_TEST_IDS_MAP[FilterTypeEnum.EventVenueCapacity] ?? '',
                                            },
                                        ]
                                        : []),
                                ],
                            },
                        ]
                        : []),
                ],
            },
            ...(isTagsFilterEnabled && tags
                ? [
                    {
                        id: 4,
                        title: t(FilterTypeEnum.Tags),
                        testId: NAV_TEST_IDS_MAP[FilterTypeEnum.Tags] ?? '',
                        content: (
                            <TagsContent
                                onUpdateActiveNavigationSubItem={onUpdateActiveNavigationSubItem}
                                tags={tags}
                            />
                        ),
                        icon: <IconFilterTags />,
                        baseColor: GradientColorsEnum.ORANGE,
                        subItems: [
                            ...(tags?.[TagsCategoryEnum.FOOD]
                                ? [
                                    {
                                        title: t(OperatorProfilePhrasesEnum.FoodCuisine),
                                        id: generateAnchorId(t(OperatorProfilePhrasesEnum.FoodCuisine)),
                                        testId: NAV_TEST_IDS_MAP[OperatorProfilePhrasesEnum.FoodCuisine] ?? '',
                                    },
                                ]
                                : []),
                            ...(tags?.[TagsCategoryEnum.PLACES]
                                ? [
                                    {
                                        title: t(OperatorProfilePhrasesEnum.Place),
                                        id: generateAnchorId(t(OperatorProfilePhrasesEnum.Place)),
                                        testId: NAV_TEST_IDS_MAP[OperatorProfilePhrasesEnum.Place] ?? '',
                                    },
                                ]
                                : []),
                        ],
                    },
                ]
                : []),
        ];

        function onUpdateActiveNavigationSubItem(id: string) {
            const isFilter = id.includes(FILTER_ID_PREFIX);

            if (isFilter) {
                setActiveNavigationFilterId(id);
                let parentSubItemId;

                items?.forEach((item) => {
                    const parentSubItem = item?.subItems?.find((subItem: OperatorTargetingNavItem) => {
                        return subItem?.filters?.filter((filter) => filter.id === id)[0];
                    });

                    parentSubItemId = parentSubItem?.id;
                });

                if (parentSubItemId) {
                    setActiveNavigationSubItemId(parentSubItemId);
                }

                return;
            }

            setActiveNavigationSubItemId(id);
        }

        return items;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, ranges, isPurchasePotEnabled, isEstMealsPerDayEnabled, filteredSegmentNavList]);

    return {
        NAVIGATION,
        activeNavigationFilterId,
        activeNavigationItemId,
        setActiveNavigationItemId,
        activeNavigationSubItemId,
        setActiveNavigationSubItemId,
        setActiveNavigationFilterId,
    };
}

export default function useSearchNavigation() {
    const {
        NAVIGATION,
        activeNavigationItemId,
        setActiveNavigationItemId,
        activeNavigationSubItemId,
        setActiveNavigationSubItemId,
        activeNavigationFilterId,
        setActiveNavigationFilterId,
    } = useGetNavigation();

    const content = useMemo(() => {
        const activeItem = NAVIGATION.find((i) => i.id == activeNavigationItemId);

        return activeItem?.content;
    }, [activeNavigationItemId, NAVIGATION]);

    const sidebarNavigationItems = useMemo(() => {
        const onItemClick = (item: iNavigationItem) => {
            if (item.id === activeNavigationItemId) {
                return;
            }

            const firstSubItem = item?.subItems?.[0];
            const firstFilter = firstSubItem?.filters?.[0]?.id;

            if (firstFilter) {
                setActiveNavigationFilterId(firstFilter);
            }

            setActiveNavigationSubItemId(firstSubItem?.id || 0);
            setActiveNavigationItemId(item.id);
        };

        const onSubItemClick = (subItem: iNavigationItem) => {
            if (!subItem?.filters?.length) {
                return;
            }

            if (subItem.id === activeNavigationSubItemId) {
                return setActiveNavigationSubItemId(0);
            }

            return setActiveNavigationSubItemId(subItem.id);
        };

        return NAVIGATION.map((item) => ({
            ...item,
            onClick: onItemClick,
            isActive: item.id === activeNavigationItemId,
            subItems: (item.subItems || []).map((subItem) => ({
                ...subItem,
                isActive: subItem.id === activeNavigationSubItemId,
                onClick: onSubItemClick,
                filters: subItem?.filters?.map((filter) => ({
                    ...filter,
                    isActive: filter.id === activeNavigationFilterId,
                })),
            })),
        }));
    }, [
        NAVIGATION,
        activeNavigationItemId,
        activeNavigationSubItemId,
        setActiveNavigationItemId,
        setActiveNavigationSubItemId,
        activeNavigationFilterId,
        setActiveNavigationFilterId,
    ]);

    useEffect(() => {
        if (activeNavigationItemId === 0) {
            const firstItem = NAVIGATION[0];
            setActiveNavigationItemId(firstItem.id);
            setActiveNavigationSubItemId(firstItem?.subItems?.[0]?.id || 0);
        }
    }, [NAVIGATION, activeNavigationItemId, setActiveNavigationItemId, setActiveNavigationSubItemId]);

    const onTitleClick = useCallback(() => {
        setActiveNavigationItemId(0);
        setActiveNavigationSubItemId(null);
    }, [setActiveNavigationItemId, setActiveNavigationSubItemId]);

    return {
        content,
        onTitleClick,
        sidebarNavigationItems,
    };
}
