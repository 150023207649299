import {
    FilterTypeEnum,
    HomePhrasesEnum,
    OperatorTargetingResultsPhrasesEnum,
    OperatorProfilePhrasesEnum,
} from '@core/enums/localeEnum';
import { FCX } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { formatNumberByLocale } from '@core/utils/string';
import { ConfidenceLevel } from '@graphql/generated/graphql';
import Collapse from '@components/utility/Collapse/Collapse';
import { WidgetType } from '@components/utility/Widget/Widget';
import DonutChart from '@components/utility/Charts/DonutChart/DonutChart';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import GroupedBarChart from '@components/utility/Charts/GroupedBarChart/GroupedBarChart';
import { PERCENTS_ID } from '@pages/OperatorTargetingResults/hooks/useGetOperatorSummaryData';
import ComparisonBarChart from '@components/utility/Charts/ComparisonBarChart/ComparisonBarChart';
import { CONFIDENCE_LEVEL_CHART_STYLES } from '@pages/OperatorTargetingResults/OperatorTargetingResults.constants';
import { useOperatorTargetingResultsContext } from '@pages/OperatorTargetingResults/OperatorTargetingResults.context';
import GroupedVerticalProgressBar from '@components/utility/Charts/GroupedVerticalProgressBar/GroupedVerticalProgressBar';

import ConfidenceLevelChartLegend from './ConfidenceLevelChartLegend';

const AllSegmentsCharts: FCX<{
    currencyCode: string;
}> = ({ currencyCode }) => {
    const {
        t,
        locale
    } = useLocale();

    const {
        summaryData: {
            aggregations,
            resultsCount,
            selectedOptionId,
            drillDown: {
                onDrillDownSegments,
                onDrillDownAnnualSalesRange,
                onDrillDownChainsVsIndependents,
                onDrillDownConfidenceLevel,
            },
        },
    } = useOperatorTargetingResultsContext();

    return (
        <Collapse
            title={
                <Heading variant={HeadingVariant.H3}>
                    {t(HomePhrasesEnum.All)} ({formatNumberByLocale(resultsCount, locale)})
                </Heading>
            }
            startCollapsed={false}
            widgetProps={{
                type: WidgetType.Section,
                className: 'section-small',
            }}
        >
            <div className="OperatorResultsSummary__charts">
                {aggregations?.segments && (
                    <div className="OperatorResultsSummary__segments">
                        <Heading variant={HeadingVariant.H4}>{t(FilterTypeEnum.Segments)}</Heading>
                        <GroupedBarChart groups={aggregations.segments} onBarClick={onDrillDownSegments} />
                    </div>
                )}
                {aggregations?.chainsVsIndependents && (
                    <div className="OperatorResultsSummary__units-comparison">
                        <Heading variant={HeadingVariant.H4}>
                            {t(OperatorTargetingResultsPhrasesEnum.ChainsVsIndependents)}
                        </Heading>
                        <ComparisonBarChart
                            data={aggregations.chainsVsIndependents}
                            showPercents={selectedOptionId === PERCENTS_ID}
                            onBarClick={onDrillDownChainsVsIndependents}
                        />
                    </div>
                )}
            </div>

            <div className="OperatorResultsSummary__charts">
                {aggregations?.confidenceLevel && (
                    <div className="OperatorResultsSummary__donut-charts">
                        <Heading variant={HeadingVariant.H4}>{t(OperatorProfilePhrasesEnum.ConfidenceLevel)}</Heading>
                        <div className="OperatorResultsSummary__chart-legend-wrapper">
                            <ConfidenceLevelChartLegend
                                aggregations={aggregations?.confidenceLevel}
                                showPercents={selectedOptionId === PERCENTS_ID}
                                locale={locale}
                                styles={CONFIDENCE_LEVEL_CHART_STYLES}
                            />
                            <DonutChart
                                data={(aggregations?.confidenceLevel || []).map((item, index) => ({
                                    id: index,
                                    value: item.value,
                                    percent: item.percent,
                                    color: `gradient${item.id?.toString()}`,
                                    label: item.id?.toString() ?? '',
                                    onClickCb: () => onDrillDownConfidenceLevel(item.id.toString() as ConfidenceLevel),
                                }))}
                                styles={CONFIDENCE_LEVEL_CHART_STYLES}
                            />
                        </div>
                    </div>
                )}

                {(aggregations?.annualSalesRange ?? []).every((item) => item.value === '0' || item.value === '0.0%') ===
                    false && (
                    <div className="OperatorResultsSummary__annual-sales">
                        <Heading variant={HeadingVariant.H4}>
                            {t(OperatorProfilePhrasesEnum.EstimatedAnnualSales)} {currencyCode}
                        </Heading>

                        <GroupedVerticalProgressBar
                            data={aggregations.annualSalesRange || []}
                            onBarClick={onDrillDownAnnualSalesRange}
                        />
                    </div>
                )}
            </div>
        </Collapse>
    );
};

export default AllSegmentsCharts;
