export enum RoutesEnum {
    Home = '/',
    OperatorTargetingCriteria = '/operator-targeting',
    OperatorTargetingResults = '/operator-targeting/results',
    OperatorProfile = '/operator-targeting/profile/:id',
    HeadquarterTargeting = '/headquarter-targeting',
    SavedSearches = '/saved-searches',
    NotFound = '/404',
    AccessDenied = '/403',
    Test = '/test',
    Territories = '/territories',
    TerritoryImport = '/territory-import',
    TerritoryAdmin = '/territory-admin',
}
