import { useEffect } from 'react';
import { AxiosError } from 'axios';

import useFetch from '@hooks/useFetch';
import { PermissionsStructure } from '@core/hooks/usePermissions';

const USER_INFO_URL = '/user/info';

export enum UserRolesEnum {
    Admin = 'ADMIN',
}

export type UserInfoApi = {
    external_id: string;
    first_name: string;
    last_name: string;
    access_key: string;
    company: {
        name: string;
        default_locale: string;
        default_country?: string;
        export_allowed: boolean;
        client_external_id_type: string;
    };
    country?: string;
    locale?: string;
    email_address: string;
    permissions: PermissionsStructure;
    features?: {
        leadscore?: boolean;
        premium_field?: string[];
        roles?: string[];
    };
};

export default function useUser() {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<UserInfoApi>({
        url: USER_INFO_URL,
        method: 'GET',
    });

    useEffect(() => {
        if (error instanceof AxiosError) {
            const status = error.response?.status;

            if (status === 404) {
                window.location.href = `${process.env.REACT_APP_SNAP_URL}`;
            }
        }
    }, [error]);

    return { data, loading, error, doFetch } as const;
}
