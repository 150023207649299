import React, { ComponentProps, useState } from 'react';
import Toggle, { ToggleType } from '@components/utility/Toggle/Toggle';
import RadioGroup from '@components/utility/RadioGroup/RadioGroup';

const TestPage: React.FC = () => {
    const defaultProps: ComponentProps<typeof RadioGroup> = {
        options: [
            { id: 1, title: 'Option 1' },
            { id: 2, title: 'Option 2' },
            { id: 3, title: 'Option 3' },
            { id: 4, title: 'Option 4' },
            { id: 5, title: 'Option 5' },
        ],
        value: 1,
        onChange: () => null,
    };
    const [value, setValue] = useState(defaultProps.value);
    const [toggleValue, setToggleValue] = useState(true);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div>
                <h1>Radio Test</h1>
                <RadioGroup {...defaultProps} value={value} onChange={setValue} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <h1>Toggle Test</h1>
                <Toggle type={ToggleType.Checkbox} isChecked={toggleValue} setIsChecked={setToggleValue} />
                <Toggle type={ToggleType.Radio} isChecked={toggleValue} setIsChecked={setToggleValue} />
                <Toggle type={ToggleType.Switch} isChecked={toggleValue} setIsChecked={setToggleValue} />
            </div>
        </div>
    );
};

export default TestPage;
